// Main colors
$black: #232323;
$true-black: #000000;
$white: #fff;
$warning: #ff8400;

// Greens
$green-primary: #115611; // En cas de changement, mettre à jour également le favicon.svg
$green-secondary: #88c168;
$green-hc: #709a70;

// Blues
$blue: #0077be;
$blue-level-1: #35758b; // auto
$blue-level-2: #43969c; // prog
$blue-level-3: #6ec6d9; // manual
$blue-level-4: #a4d5ca; // drain
$blue-opacity: 0.1;
$blue-background: rgba($blue-level-1, $blue-opacity);
$blue-background-2: rgba($blue-level-2, $blue-opacity);
$blue-background-3: rgba($blue-level-3, $blue-opacity);
$blue-background-4: rgba($blue-level-4, $blue-opacity);
$best-ventilation-area: rgba(#4285f4ff, 0.2);
$blue-not-remotely-controlled: #95b7c2;
$plan-background: #f8f9ff;

// Greys
$grey-light: #ebebeb;
$grey: #d6d6d6;
$grey-dark: #7a7a7a;
$grey-darker: #444444;
$fade-background: rgba(255, 255, 255, 0.7);
$grey-hp: #aaaaaa;

// yellows
$yellow-light: #f8edd0;
$yellow: #f6e2ad;
$yellow-dark: #f6d479;
$yellow-darker: #e3c26c;

// Map colors
$green-fill: #c1e6b3;
$green-stroke: #179851;
$yellow-fill: #ffe3ae;
$yellow-stroke: #ff8400;
$red-fill: #ffb4b0;
$red-stroke: #e61610;
$white-fill: $white;
$white-stroke: $black;
$grey-fill: $grey-light;
$grey-stroke: $black;

// Dashboard chart colors
$orange-1: #e28a33;
$orange-2: #e0ad19;
$orange-3: #f5d261;
$orange-4: #f9e39c;

// CMMS colors
$weekly-fill: #fef9eb;
$weekly-stroke: #f9e39c;
$monthly-fill: #fff8f7;
$monthly-stroke: #ffdad8;
$quarterly-fill: #fcf4fe;
$quarterly-stroke: #f2c9fb;
$bi-annual-fill: #f8f4fe;
$bi-annual-stroke: #dacafb;
$yearly-fill: #f6f9fe;
$yearly-stroke: #d4e3fc;
$unplanned-fill: #fff7ef;
$unplanned-stroke: #ffd8ad;
$one-off-fill: #fff0ef;
$one-off-stroke: #ffb4b0;

// Notification colors
$notif-red: #fff0ef;
$notif-blue: #e9f1f3;
$notif-yellow: #f9efd1;
$notif-grey: #f7f6f6;

// Définition des couleurs en variable CSS pour les récupérer en JS, cf fonction & enum dans src/app/shared/utils/Color.utils.ts
:root {
  --app-green-primary: #{$green-primary};
  --app-green-secondary: #{$green-secondary};
  --app-white: #{$white};
  --app-black: #{$black};
  --app-true-black: #{$true-black};
  --app-grey: #{$grey};
  --app-grey-light: #{$grey-light};
  --app-grey-dark: #{$grey-dark};
  --app-grey-darker: #{$grey-darker};

  // Map colors
  --app-green-stroke: #{$green-stroke};
  --app-yellow-stroke: #{$yellow-stroke};
  --app-red-stroke: #{$red-stroke};
  --app-red-fill: #{$red-fill};
  --app-green-fill: #{$green-fill};
  --app-yellow-fill: #{$yellow-fill};
  --app-white-fill: #{$white-fill};
  --app-white-stroke: #{$white-stroke};

  // Blues
  --app-blue: #{$blue};
  --app-blue-level-1: #{$blue-level-1};
  --app-blue-level-2: #{$blue-level-2};
  --app-blue-level-3: #{$blue-level-3};
  --app-blue-level-4: #{$blue-level-4};
  --app-best-ventilation-area: #{$best-ventilation-area};

  //hp/hc colors
  --app-green-hc: #{$green-hc};
  --app-grey-hp: #{$grey-hp};

  // Dashboard chart colors
  --app-orange-1: #{$orange-1};
  --app-orange-2: #{$orange-2};
  --app-orange-3: #{$orange-3};
  --app-orange-4: #{$orange-4};

  // CMMS colors
  --app-weekly-fill: #{$weekly-fill};
  --app-weekly-stroke: #{$weekly-stroke};
  --app-monthly-fill: #{$monthly-fill};
  --app-monthly-stroke: #{$monthly-stroke};
  --app-quarterly-fill: #{$quarterly-fill};
  --app-quarterly-stroke: #{$quarterly-stroke};
  --app-bi-annual-fill: #{$bi-annual-fill};
  --app-bi-annual-stroke: #{$bi-annual-stroke};
  --app-yearly-fill: #{$yearly-fill};
  --app-yearly-stroke: #{$yearly-stroke};
  --app-unplanned-fill: #{$unplanned-fill};
  --app-unplanned-stroke: #{$unplanned-stroke};
  --app-one-off-fill: #{$one-off-fill};
  --app-one-off-stroke: #{$one-off-stroke};
}
