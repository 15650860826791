$border-radius-small: 6px;
$border-radius: 16px;

$box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
$box-shadow-switch: 0 1px 2px rgba(0, 0, 0, 0.39);

$base-icon-size: 16px;
$close-icon-size: 24px;

$sidenav-height: 62px;
