@use 'mixins';

.intercom-app > div:not(.intercom-messenger-frame) {
  bottom: 70px !important;

  @include mixins.lg {
    bottom: 20px !important;
  }
}

.intercom-lightweight-app-launcher {
  bottom: 70px !important;

  @include mixins.lg {
    bottom: 20px !important;
  }
}
