@use 'sass:map';
@use 'breakpoints';

// Define breakpoints for responsive design
$breakpoints: (
  xs: 0,
  sm: breakpoints.$screen-sm-min,
  md: breakpoints.$screen-md-min,
  lg: breakpoints.$screen-lg-min,
  xl: breakpoints.$screen-xl-min,
  xxl: breakpoints.$screen-xxl-min,
  x2k: breakpoints.$screen-x2k-min,
  x4k: breakpoints.$screen-x4k-min
);

// Function to generate media query based on breakpoints
@function media-query($breakpoint) {
  @if map.has-key($breakpoints, $breakpoint) {
    $query: map.get($breakpoints, $breakpoint);
    @return 'only screen and (min-width: #{$query})';
  } @else {
    @error "Invalid breakpoint: #{$breakpoint}.";
  }
}

// Container class
.container {
  margin-right: auto;
  margin-left: auto;
  overflow: hidden auto;
  padding: 16px;
  height: 100%;
  box-sizing: border-box;
}

// Row class
.row {
  display: flex;
  flex-wrap: wrap;
}

// Col class
.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

// Column classes
@for $i from 1 through 12 {
  .col-#{$i} {
    flex: 0 0 calc(100% / 12 * #{$i});
    max-width: calc(100% / 12 * #{$i});
    box-sizing: border-box;
  }
}

// Responsive column classes
@each $breakpoint in map.keys($breakpoints) {
  @for $i from 1 through 12 {
    .col-#{$breakpoint}-#{$i} {
      @media #{media-query($breakpoint)} {
        flex: 0 0 calc(100% / 12 * #{$i});
        max-width: calc(100% / 12 * #{$i});
        box-sizing: border-box;
      }
    }
  }
}
