@use 'breakpoints';

// Small devices
@mixin sm {
  @media (min-width: #{breakpoints.$screen-sm-min}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: #{breakpoints.$screen-md-min}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{breakpoints.$screen-lg-min}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: #{breakpoints.$screen-xl-min}) {
    @content;
  }
}

// Large desktops
@mixin xxl {
  @media (min-width: #{breakpoints.$screen-xxl-min}) {
    @content;
  }
}

// Very large desktops
@mixin x2k {
  @media (min-width: #{breakpoints.$screen-x2k-min}) {
    @content;
  }
}

// Antonio's screen
@mixin x4k {
  @media (min-width: #{breakpoints.$screen-x4k-min}) {
    @content;
  }
}

// Shitty devices
@mixin ios {
  @supports (-webkit-touch-callout: none) {
    @content;
  }
}
