$title1-font-size: 24px;
$title2-font-size: 20px;
$title3-font-size: 18px;
$nav-font-size: 16px;
$action-font-size: 14px;
$default-font-size: 14px;
$small-font-size: 12px;
$smallest-font-size: 11px;

$font-light: Nunito-Light, sans-serif !important;
$font-bold: Nunito-SemiBold, sans-serif !important;
