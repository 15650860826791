// Not in index.scss because it should be imported only once
@use 'assets/styles/font-faces';
@use 'assets/styles/animations';
@use 'assets/styles/scrollbar';
@use 'assets/styles/dropdown';
@use 'assets/styles/grid.scss';
@use 'assets/styles/intercom.scss';
@use 'assets/styles/colors';
@use 'assets/styles/fonts';
@use 'assets/styles/misc';

.mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: misc.$border-radius !important;
}

html,
input,
textarea {
  font-family: fonts.$font-light;
  font-size: fonts.$default-font-size;
  font-weight: normal;
  color: colors.$black;
}

button,
a {
  font-size: fonts.$action-font-size;
  font-weight: normal;
  font-family: fonts.$font-bold;
  color: colors.$black;
  outline: none;
}

h1 {
  font-size: fonts.$title1-font-size;
  font-weight: normal;
  font-family: fonts.$font-bold;
  margin: 0;
}

h2 {
  font-size: fonts.$title2-font-size;
  font-weight: normal;
  font-family: fonts.$font-bold;
  margin: 0;
}

h3 {
  font-size: fonts.$title3-font-size;
  font-weight: normal;
  font-family: fonts.$font-bold;
  margin: 0;
}

svg-icon,
svg:not(.highcharts-root) {
  width: misc.$base-icon-size;
  height: misc.$base-icon-size;
}

html,
body {
  height: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  cursor: auto !important;
}

hr {
  border: none;
  border-bottom: 1px solid colors.$grey-light;
  width: 100%;

  &.green {
    border-color: colors.$green-primary;
  }
}

.error-message {
  font-size: fonts.$smallest-font-size;
  color: colors.$red-stroke;
}

.cdk-overlay-pane {
  transform: translate3d(0, 0, 0);
}

.mdc-linear-progress__buffer-bar {
  background-color: colors.$grey-light !important;
}

.mdc-linear-progress__bar-inner {
  border-color: colors.$green-primary !important;
}
