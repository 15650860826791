@use 'colors';

$scrollbar-width: 6px;
$scrollbar-border-radius: 6px;
$scrollbar-background-color: colors.$grey-light;
$scrollbar-thumb-color: colors.$grey-dark;
$scrollbar-thumb-hover-color: #666;

/* Custom Scrollbar Styles */
::-webkit-scrollbar {
  width: $scrollbar-width; /* Width of the scrollbar */
  height: $scrollbar-width; /* Width of the horizontal scrollbar */
}

::-webkit-scrollbar-track {
  background-color: $scrollbar-background-color; /* Track color (the background) */
  border-radius: $scrollbar-border-radius;
}

::-webkit-scrollbar-thumb {
  background-color: $scrollbar-thumb-color; /* Thumb color (the draggable part) */
  border-radius: $scrollbar-border-radius; /* Rounded corners for the thumb */
}

::-webkit-scrollbar-thumb:hover {
  background-color: $scrollbar-thumb-hover-color; /* Thumb color on hover */
}

/* Optional: Scrollbar width for Firefox */
@-moz-document url-prefix() {
  * {
    scrollbar-width: thin;
    scrollbar-color: $scrollbar-thumb-color $scrollbar-background-color;
  }
}

/* Optional: Scrollbar width for Edge/IE */
html {
  -ms-overflow-style: none; /* Hide scrollbar in Edge/IE */
  scrollbar-width: thin;
}

body::-webkit-scrollbar {
  width: $scrollbar-width; /* Width of the scrollbar */
  height: $scrollbar-width; /* Width of the horizontal scrollbar */
}

body::-webkit-scrollbar-track {
  background-color: $scrollbar-background-color; /* Track color (the background) */
  border-radius: $scrollbar-border-radius;
}

body::-webkit-scrollbar-thumb {
  background-color: $scrollbar-thumb-color; /* Thumb color (the draggable part) */
  border-radius: $scrollbar-border-radius; /* Rounded corners for the thumb */
}

body::-webkit-scrollbar-thumb:hover {
  background-color: $scrollbar-thumb-hover-color; /* Thumb color on hover */
}
